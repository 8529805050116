.product-pagination .pagination .page-item span {
    padding: 18px;
}

.product-pagination .pagination {
    justify-content: center;
}

.add_to_cart.right.open-side {
    width: 400px;
}

.cart-count {
    background-color: red;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    position: absolute;
    top: -10px;
    left: 15px;
    width: 25px;
    font-size: 14px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#main-nav .pixelstrap ul ul {
    /*margin-left: -223px!important;*/
    max-width: 30em!important;
}

#main-nav .pixelstrap ul {
    max-width: 30em!important;
}